import React from "react";
import "../css/Constitution.scss";

function Constitution() {
  return (
    <>
      <section id="constitution" className="constitution">
        <h1>Constitution</h1>
      </section>
    </>
  );
}

export default Constitution;
