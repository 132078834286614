import React from 'react'
import "../css/Magazines.scss";

function Magazines() {
  return (
    <section id='magazines' className='magazines'>
        <h1>Magazines</h1>
    </section>
  )
}

export default Magazines